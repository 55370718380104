<template>
  <b-card>
    <template v-if="loading || !profile">
      <div class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
      </div>
    </template>
    <template v-else>
      <b-card-title>Perfil - {{ profile.name }}</b-card-title>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
              <div class="m-2">
                <table class="table b-table table-hover ">
                  <thead>
                    <tr>
                      <th>Nome do usuário</th>
                      <th>Login</th>
                      <th>Data de vinculação</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <template v-for="user in users">
                    <tr>
                      <td class="w-25">{{ user.name }}</td>
                      <td>
                        {{ user.email }}
                      </td>
                      <td>
                        {{ user.vinculated_date | dateTime }}
                      </td>
                      <td>
                        <b-button
                          :id="`remove-user-${user.id}`"
                          class="btn-icon mr-1"
                          variant="flat"
                          style="padding: 0"
                          @click="removeUser(user)"
                        >
                          <feather-icon icon="TrashIcon" class="mr-50" />
                        </b-button>
                        <b-tooltip
                          :target="`remove-user-${user.id}`"
                          triggers="hover"
                        >
                          Remover usuário
                        </b-tooltip>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!addingUser">
                    <tr>
                      <td>
                        <b-button
                          variant="outline-primary"
                          @click.prevent="addingUser = true"
                        >
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          <span>
                            Adicionar novo usuário
                          </span>
                        </b-button>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                </template>
                <template v-else>
                  <tr>
                      <td>
                        <v-select
                          id="is-profile-active"
                          v-model="newUser"
                          :options="availableUsers"
                          :reduce="(user) => user.id"
                          label="name"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="outline-success"
                          class="mr-1"
                          @click="addUser"
                        >
                          <feather-icon icon="PlusIcon" class="mr-50" />
                          Adicionar
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          @click="addingUser = false"
                        >
                          <feather-icon icon="XIcon" class="mr-50" />
                          Cancelar
                        </b-button>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                </template>
                </table>
                <br>
              </div>
          </b-col>
          <b-col cols="12">
            <b-row class="justify-content-end">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'companies-list' }"
                :disabled="saving"
              >
                Cancelar
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mx-1"
                @click.prevent="save"
              >
                <b-spinner v-if="saving" small />
                <feather-icon v-else icon="SaveIcon" class="mr-50" />
                <span>
                  {{ saving ? "Salvando..." : "Salvar" }}
                </span>
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin }
  },
  data() {
    return {
      loading: false,
      saving: false,
      users: [],	
      profile: undefined,
      addingUser: false,
      newUser: undefined,
    }
  },
  computed: {
    ...mapGetters({
      departmentUsers: types.DEPARTMENT_USERS
    }),
    availableUsers() {
      const selectedUsers = _.map(this.users, 'id')
      return _.filter(this.departmentUsers, function(user) {
        return !selectedUsers.includes(user.id)
      })
    }
  },
  mounted() {
    this.loading = true
    this.getProfileUsers(this.$route.params.id)
      .then((response) => {
        if(response.data){
          this.users = response.data.users 
          this.profile = response.data.profile
          this.loadingDepartmentUsers()
        }else{
          throw new DOMException("Ocorreu um erro ao carregar");
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os usuários para a seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions({
      getProfileUsers: types.GET_ACCESS_PROFILE_USERS,
      saveProfileUsers: types.SAVE_ACCESS_PROFILE_USERS,
      getDepartmentUsers: types.GET_DEPARTMENT_USERS
    }),
    addUser(){
      const user = _.find(this.availableUsers, ['id', this.newUser])
      this.users.push(user)
      this.addingUser = false
      this.newUser = undefined
    },
    removeUser(user){
      this.$swal({
        title: "Confirmação",
        text: `Deseja realmente remover ${user.name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          const index = _.findIndex(this.users, ['id', user.id])
          this.users.splice(index, 1)
        }
      })
    },
    loadingDepartmentUsers(){
      this.getDepartmentUsers(this.profile.department_id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar os usuários para a seleção. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async save() {
      this.saving = true
      let payload = {
        id: this.$route.params.id,
        users: _.map(this.users, 'id')
      }
      this.saveProfileUsers(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `Usuários salvo com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        //  this.$router.push({ name: 'companies-list' })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao salvar os usuários. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>
